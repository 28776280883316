import $ from "jquery";

/**
 * jQuery form plugin to do simple validation
 */
$.fn.form = function () {
    $(this).each(function () {
        const form = this;
        
        $(this).find("[type=submit]").on("click", function (e) {
            $(form).addClass("was-validated");
            
            if (form.checkValidity && ! form.checkValidity()) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }
            
            $(this).blur();
        });
    });
};
